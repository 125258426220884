<template>
  <div class="section">
    <div class="hero is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-10">
              <div class="box">
                <div class="columns is-multiline is-centered is-vcentered">
                  <div class="column is-6-desktop is-10-tablet">
                    <img class="logo_view" src="@/assets/logo_asiaibs.png" alt="Logo AsiaIBS">
                  </div>
                  <div class="column is-6-desktop is-10-tablet">
                    <form
                      @submit.prevent="loginUser"
                      class="columns is-multiline is-centered"
                    >
                      <div class="column is-12">
                        <h1 class="form_title">
                          Inicia Sesión
                        </h1>
                      </div>
                      <div class="column is-8">
                        <div class="field">
                          <div class="control has-icons-left">
                            <input
                              class="input is-rounded"
                              :class="{'is-danger': vverrors.has('email')}"
                              type="email"
                              autocomplete="on"
                              v-model="email"
                              name="email"
                              placeholder="Correo electrónico"
                              v-validate="'required|email'"
                            >
                            <span class="icon is-small is-left">
                              <img class="input_icon" src="@/assets/icon/email-form.png" alt="email">
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="column is-8">
                        <div class="field">
                          <div class="control has-icons-left">
                            <input
                              class="input is-rounded"
                              :class="{'is-danger': vverrors.has('password')}"
                              type="password"
                              v-model="password"
                              name="password"
                              placeholder="Contraseña"
                              v-validate="'required'"
                            >
                            <span class="icon is-small is-left">
                              <img class="input_icon_pass" src="@/assets/icon/lock-icon.png" alt="password">
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="column is-8">
                        <button
                          class="button button_ibs is-rounded is-fullwidth"
                          :class="{ 'is-loading': loading }"
                          type="submit"
                        >
                          Iniciar Sesión
                        </button>
                      </div>
                      <div class="column is-10 has-text-centered">
                        <router-link class="form_forgot_text" to="/forgot-password">
                          ¿Olvidaste tu Contraseña?
                        </router-link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      email: null,
      password: null,
      loading: false
    }
  },
  computed: {
    ...mapState({ authentication: state => state.authentication })
  },
  methods: {
    ...mapMutations(['setAuthenticationValue']),
    ...mapActions(['login']),
    async loginUser () {
      if (await this.$validator.validateAll()) {
        this.loading = true
        const data = {
          email: this.email,
          password: this.password
        }
        await this.login(data)
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
  .section {
    background-image: url('~@/assets/background/bg-access.png');
    background-position: center;
    background-size: cover;
  }
  .is-fullheight {
    min-height: calc(100vh - 6rem);
  }
  .box {
    border-radius: 5px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e1e5eb;
    background-color: #ffffff;
    padding: 10% 5%;
  }

  .form_title {
    font-size: 28px;
    font-weight: bold;
    line-height: 1.56;
    text-align: center;
    color: #404254;
  }
  .input_icon {
    width: 16px;
  }
  .input_icon_pass {
    width: 12px;
  }

  .form_forgot_text {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    color: #404254;
  }
  .form_forgot_text:hover {
    color: #33343d;
  }

  .logo_view {
    max-width: 350px;
    display: block;
    margin: auto;
  }
</style>
